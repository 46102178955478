// src/keycloak.js
import Keycloak from 'keycloak-js';

// Inisialisasi konfigurasi Keycloak
const keycloak = new Keycloak({
  url: 'http://auth.igg.co.id',
  realm: 'monitoring',
  clientId: 'streaming'
});

export default keycloak;
