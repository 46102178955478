// src/main.js
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import keycloak from './keycloak';
import './plugins/iview.js'
import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// Inisialisasi Keycloak
keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
  if (authenticated) {
    new Vue({
      router,
      pinia,
      render: h => h(App),
    }).$mount('#app');
  }
});
