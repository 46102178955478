<template>
  <div class="home">
    <!-- <p v-if="isAuthenticated">Logged in as {{ user.preferred_username }}</p> -->
    <Card>
       <p slot="title">
            <Icon type="ios-film-outline"></Icon>
            <b>  Monitoring</b> 
        </p>
        <a href="#" slot="extra"  @click="logout">
          <p v-if="isAuthenticated">Logged in as {{ user }}</p>
            <!-- <Icon type="ios-loop-strong"></Icon> -->
            <Icon type="ios-contact" />|
            Logout
        </a>
      <Table border :columns="columns" :data="cameras">
        <template slot-scope="{ row }" slot="action">
          <Button type="primary" size="small" @click="showVideo(row)">Video</Button>
        </template>
      </Table>

    </Card>
    <VideoModal v-model="modalShow" :modal-data="modalData"></VideoModal>

  </div>
</template>

<script>
import { useAuthStore } from '../stores/auth';
import VideoModal from '@/components/VideoModal.vue';

export default {
  name: 'HomeView',
  components: {
      VideoModal
    },
    data () {
      return {
        selected:'',
        columns: [
          {
            title: 'No',
            key: 'id'
          },
          {
            title: 'Keterangan',
            key: 'desc'
          },
          {
            title: 'Action',
            slot: 'action',
            width: 150,
            align: 'center'
          }
        ],
        modalShow: false,
        modalData: null
      }
    },
    created() {
      this.getCamera()
      
      // console.log(modalData)
    },
    methods: {
        getCamera() {
        this.cameras = [
          {
            id: 1,
            name: "CCTV 1",
            desc: 'Analisa QA',
            alamat: "rtsp://192.168.20.7/0"
          },
          {
            id: 2,
            name: "CCTV 2",
            desc: 'Mill 1',
            alamat: "rtsp://192.168.60.7/12"
          },
          {
            id: 3,
            name: "CCTV 3",
            desc: 'Mill 2',
            alamat: "rtsp://192.168.60.7/0"
          }
        ];
       
    },    
    showVideo(row) {
        // console.log('this.selected :',this.selected)
        // this.modalData = {...this.selected}
        this.modalData = {...row}
          /* eslint-disable */
        console.log(row)

        this.modalShow = true
      }
    },
  setup() {
    const authStore = useAuthStore();
    return {
      isAuthenticated: authStore.isAuthenticated,
      user: authStore.user,
      logout: authStore.logout
    };
  }

};
</script>

