<template>
  <div>
    <Modal
      width="800"
      :value="show"
      :fullscreen="fullscreen"
      @on-cancel="close"
      >
       <p slot="header">
          {{modalData && modalData.desc}}
          <Button type="text" size="small" icon="ios-expand" title="CCTV"  @click="toggleFullscreen"></Button>
        </p>
      <!-- <video id="myVideo" ref="player" width="100%" height="450"></video> -->
      <video ref="player" width="100%" height="1024"></video>

      <div slot="footer">
        <Button type="success" @click="close">Close</Button>
      </div>
    </Modal>
    <Modal
      width="50"
      :value="showInfo"
      @on-cancel="closeInfo"
      >
        <p slot="header">
          <span style="color: red">INFO</span>
        </p>
        <div>
          <p>CCTV tidak dapat ditampilkan, silahkan cek Perangkat atau Jaringan CCTV !!!</p>
        </div>
      <div slot="footer">
        <Button type="error" @click="closeInfo">Oke</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import flvjs from 'flv.js'

  export default {
    model: {
      prop: 'show',
      event: 'toggleVisible'
    },
    props: {
      show: Boolean,
      modalData: Object
    },
    data () {
      return {
        showInfo:false,
        fullscreen: false,
        rtspUrl: '',
      }
    },
    computed: {
      streamId () {
        return this.modalData ? this.modalData.id : 0
      }
    },
    watch: {
      show (value) {
        if(value) {
          this.playVideo()
        } else {
          this.destroyVideo()
        }
      }
    },
    created () {
      this.getModalData()
      
      this.connection = new WebSocket("ws://192.168.1.216:8888")
      this.connection.onmessage = function(event) {
        /* eslint-disable */
        console.log(event);
        // console.log(modalData)
      }
      this.connection.onopen = function(event) {
        console.log(event)
        console.log("Successfully connected to the echo websocket server...")
      }
    },
    methods: {
      getModalData(){
        // console.log('modalData :', this.modalData)
      },
      close() {
        this.$emit('toggleVisible', false)
        this.destroyVideo()
      },
      closeInfo() {
        clearInterval(this.inter);
        this.showInfo = false;
        const self = this
        setTimeout(() => {
          self.$emit('toggleVisible', false)
          self.destroyVideo()
        }, 300) 
      },
      toggleFullscreen () {
        this.fullscreen = !this.fullscreen
      },
      playVideo () {
        /* eslint-disable */
        // console.log(modalData.url)
        if (flvjs.isSupported()) {
          let video = this.$refs.player
          if (video) {
            if (this.player) {
              this.destroyVideo()
            }
             /* eslint-disable */
            console.log(this.modalData.alamat)
            this.player = flvjs.createPlayer({
              type: 'flv',
              isLive: true,
              hasAudio: false,
              enableStashBuffer: false, // Enable IO stash buffer. Set to false if you need realtime (minimal latency) for live stream playback, but may stalled if there's network jittering.
              // url: `ws://${this.selectedIp.ip}/rtsp/${this.streamId}/?url=${this.rtspUrl}${this.modalData.channel}`
              // url: `ws://simpg.igg.co.id:8888/rtsp/${this.streamId}/?url=rtsp://${this.modalData.user_cctv_r}:${this.modalData.password_cctv_r}@${this.modalData.ip_cctv_r}:554/Streaming/Channels/${this.modalData.channel_cctv}`
              url: `ws://simpg.igg.co.id:8888/rtsp/0/?url=${this.modalData.alamat}`
              /* eslint-disable */
              // console.log(url)
              // url: `ws://localhost:8888/rtsp/${this.streamId}/?url=rtsp://${this.modalData.user_cctv_r}:${this.modalData.password_cctv_r}@${this.modalData.ip_cctv_r}:554/Streaming/Channels/${this.modalData.channel_cctv}`
            
            })
            this.player.attachMediaElement(video)
            try {
              this.player.load()
              this.player.play() 
              /* eslint-disable */
              console.log('play')
            } catch (error) {
              /* eslint-disable */
              console.log('CEK ERROR E :',error)
              this.$Notice.error({
                title: 'ERROR',
                desc: error
              })
              /* eslint-disable */
              console.log('CEK ERROR :', this.$Notice)
            }
              
            this.inter = setInterval(this.cekVideo, 10000);
            if (document.hidden !== undefined) {
              document.addEventListener('visibilitychange', this.justifyPlayTime)
            }
          }
        }
      },

      cekVideo () {
          let video = this.$refs.player
          let buffered = video.buffered
          if (buffered.length > 0) {
            /* eslint-disable */
            console.log('VIDEO IS PLAYING')
            let end = buffered.end(0)
            if (end - video.currentTime > 0.15) {
              video.currentTime = end - 0.1
            }
            clearInterval(this.inter);
          }else{
            this.showInfo = false
            this.close
            clearInterval(this.inter);
          }
      },
      justifyPlayTime () {
        /* eslint-disable */
        console.log('CEK WHAT IS THIS :',document.hidden)
        if (!document.hidden) {
          //
          let video = this.$refs.player
          let buffered = video.buffered
          if (buffered.length > 0) {
            let end = buffered.end(0)
            if (end - video.currentTime > 0.15) {
              video.currentTime = end - 0.1
            }
          }
        } else {
          // 
        }
      },
      destroyVideo () {
        if (this.player) {
          this.player.pause()
          this.player.unload()
          this.player.detachMediaElement()
          this.player.destroy()
          this.player = null
          document.removeEventListener('visibilitychange', this.justifyPlayTime)
        }
      },
    }
  }
</script>

<style lang="less" scoped>

</style>