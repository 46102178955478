// src/stores/auth.js
import { defineStore } from 'pinia';
import keycloak from '../keycloak';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    token: null,
    user: {}
  }),
  actions: {
    async login() {
      try {
        await keycloak.init({ onLoad: 'login-required' });
        this.isAuthenticated = keycloak.authenticated;
        this.user.username = keycloak.idTokenParsed.preferred_username;
        this.token = keycloak.token;
        this.user = keycloak.tokenParsed;
      } catch (error) {
        console.error('Failed to initialize Keycloak', error);
      }
    },
    logout() {
      keycloak.logout();
      this.isAuthenticated = false;
      this.token = null;
      this.user = null;
    }
  }
});
